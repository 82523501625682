// src/components/Services.js
import React from 'react';

const Services = () => {
  return (
    <section className="py-20">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-10 text-center">Nos principaux Services</h2>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/4 px-4 mb-8">
            <div className="p-6 bg-white shadow-lg rounded-lg">
              <div className='flex items-center gap-x-4'>
                <div className=' bg-blue-100 p-3 rounded-full'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd" d="M3 2.25a.75.75 0 0 0 0 1.5v16.5h-.75a.75.75 0 0 0 0 1.5H15v-18a.75.75 0 0 0 0-1.5H3ZM6.75 19.5v-2.25a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75ZM6 6.75A.75.75 0 0 1 6.75 6h.75a.75.75 0 0 1 0 1.5h-.75A.75.75 0 0 1 6 6.75ZM6.75 9a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75ZM6 12.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 0 1.5h-.75a.75.75 0 0 1-.75-.75ZM10.5 6a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75Zm-.75 3.75A.75.75 0 0 1 10.5 9h.75a.75.75 0 0 1 0 1.5h-.75a.75.75 0 0 1-.75-.75ZM10.5 12a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75ZM16.5 6.75v15h5.25a.75.75 0 0 0 0-1.5H21v-12a.75.75 0 0 0 0-1.5h-4.5Zm1.5 4.5a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Zm.75 2.25a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75v-.008a.75.75 0 0 0-.75-.75h-.008ZM18 17.25a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Z" clip-rule="evenodd" />
                  </svg>
                </div>
                <h3 className="text-2xl font-bold mb-4">Création d'entreprise</h3>

              </div>
              <ul className="list-none pl-8 space-y-2">
                <li className="relative group">
                  <a href="#sasu" className="text-lg font-medium text-gray-700 block">
                    Créer une entreprise individuelle
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#sas" className="text-lg font-medium text-gray-700 block">
                    Créer une SARL
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#sarl" className="text-lg font-medium text-gray-700 block">
                    Créer une SAS
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#eurl" className="text-lg font-medium text-gray-700 block">
                    Créer une SASU
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#auto-entrepreneur" className="text-lg font-medium text-gray-700 block">
                    Créer une Startup
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#entreprise-individuelle" className="text-lg font-medium text-gray-700 block">
                    Créer une filiale
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#sci" className="text-lg font-medium text-gray-700 block">
                    Créer une ONG
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#association" className="text-lg font-medium text-gray-700 block">
                    Créer une association
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#tous-les-statuts" className="text-lg font-medium text-gray-700 block">
                    Créer une Association
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#tous-les-statuts" className="text-lg font-medium text-gray-700 block">
                    Créer une Fondation
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#tous-les-statuts" className="text-lg font-medium text-gray-700 block">
                    Créer une SCOOPS
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#tous-les-statuts" className="text-lg font-medium text-gray-700 block">
                    Créez une SCI
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
              </ul>


            </div>
          </div>
          {/*  */}
          <div className="w-full md:w-1/4 px-4 mb-8">
            <div className="p-6 bg-white shadow-lg rounded-lg">
              <div className='flex items-center gap-x-4'>
                <div className=' bg-green-100 p-3 rounded-full'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd" d="M7.5 5.25a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0 1 12 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 0 1 7.5 5.455V5.25Zm7.5 0v.09a49.488 49.488 0 0 0-6 0v-.09a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5Zm-3 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                    <path d="M3 18.4v-2.796a4.3 4.3 0 0 0 .713.31A26.226 26.226 0 0 0 12 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 0 1-6.477-.427C4.047 21.128 3 19.852 3 18.4Z" />
                  </svg>
                </div>
                <h3 className="text-2xl font-bold mb-4">Gestion d'entreprise</h3>

              </div>

              <ul className="list-none pl-8 space-y-2">
                <li className="text-lg font-medium text-gray-700 relative group">
                  <a href="#sasu" className="text-lg font-medium text-gray-700 block">
                    Changement de gérant</a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="text-lg font-medium text-gray-700 relative group">
                  <a href="#sasu" className="text-lg font-medium text-gray-700 block">
                    Cession de part</a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="text-lg font-medium text-gray-700 relative group">
                  <a href="#sasu" className="text-lg font-medium text-gray-700 block">
                    Modification d'activités</a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="text-lg font-medium text-gray-700 relative group">
                  <a href="#sasu" className="text-lg font-medium text-gray-700 block">
                    Changement de nom</a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="text-lg font-medium text-gray-700 relative group">
                  <a href="#sasu" className="text-lg font-medium text-gray-700 block">
                    Augmentation de capital</a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="text-lg font-medium text-gray-700 relative group">
                  <a href="#sasu" className="text-lg font-medium text-gray-700 block">
                    Changement d’adresse</a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="text-lg font-medium text-gray-700 relative group">
                  <a href="#sasu" className="text-lg font-medium text-gray-700 block">
                    Modification de statut</a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="text-lg font-medium text-gray-700 relative group">
                  <a href="#sasu" className="text-lg font-medium text-gray-700 block">
                    Domiciliation</a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="text-lg font-medium text-gray-700 relative group">
                  <a href="#sasu" className="text-lg font-medium text-gray-700 block">
                    Tous les statuts</a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
              </ul>

              {/*  */}
            </div>
          </div>
          {/*  */}
          <div className="w-full md:w-1/4 px-4 mb-8">
            <div className="p-6 bg-white shadow-lg rounded-lg">
              <div className='flex items-center gap-x-4'>
                <div className=' bg-orange-100 p-3 rounded-full'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd" d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z" clip-rule="evenodd" />
                    <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
                  </svg></div>
                <h3 className="text-2xl font-bold mb-4">Accompagnement</h3>

              </div>
              <p className='text-lg font-semibold italic'>Une assistance comptable au quotidien</p>
              <p className='mb-2'>Simplifiez votre quotidien avec notre expertise comptable. Confiez-nous la gestion de votre comptabilité, paie, rédactions de contrat. Vous pourrez vous concentrer sur votre cœur de métier. </p>
              {/* <p><b>Votre satisfaction est notre priorité</b></p> */}

              <ul className="list-none pl-8 space-y-2">
                <li className="relative group">
                  <a href="#sasu" className="text-lg font-medium text-gray-700 block">
                    Comptabilité
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#sas" className="text-lg font-medium text-gray-700 block">
                    Paie
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#sarl" className="text-lg font-medium text-gray-700 block">
                    Rédaction de contrat
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#eurl" className="text-lg font-medium text-gray-700 block">
                    Recouvrement de créance
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>

              </ul>

            </div>
          </div>
          {/*  */}
          <div className="w-full md:w-1/4 px-4 mb-8">
            <div className="p-6 bg-white shadow-lg rounded-lg">
              <div className='flex items-center gap-x-4'>
                <div className=' bg-orange-50 p-3 rounded-full'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd" d="M12 5.25c1.213 0 2.415.046 3.605.135a3.256 3.256 0 0 1 3.01 3.01c.044.583.077 1.17.1 1.759L17.03 8.47a.75.75 0 1 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 0 0-1.06-1.06l-1.752 1.751c-.023-.65-.06-1.296-.108-1.939a4.756 4.756 0 0 0-4.392-4.392 49.422 49.422 0 0 0-7.436 0A4.756 4.756 0 0 0 3.89 8.282c-.017.224-.033.447-.046.672a.75.75 0 1 0 1.497.092c.013-.217.028-.434.044-.651a3.256 3.256 0 0 1 3.01-3.01c1.19-.09 2.392-.135 3.605-.135Zm-6.97 6.22a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.752-1.751c.023.65.06 1.296.108 1.939a4.756 4.756 0 0 0 4.392 4.392 49.413 49.413 0 0 0 7.436 0 4.756 4.756 0 0 0 4.392-4.392c.017-.223.032-.447.046-.672a.75.75 0 0 0-1.497-.092c-.013.217-.028.434-.044.651a3.256 3.256 0 0 1-3.01 3.01 47.953 47.953 0 0 1-7.21 0 3.256 3.256 0 0 1-3.01-3.01 47.759 47.759 0 0 1-.1-1.759L6.97 15.53a.75.75 0 0 0 1.06-1.06l-3-3Z" clip-rule="evenodd" />
                  </svg></div>
                <h3 className="text-2xl font-bold mb-4">Autres Démarches</h3>

              </div>


              <ul className="list-none pl-8 space-y-2">
                <li className="relative group">
                  <a href="#sasu" className="text-lg font-medium text-gray-700 block">
                    Formalité ACD
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#sas" className="text-lg font-medium text-gray-700 block">
                    Agrément
                    FDFP
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left"></span>
                </li>
                <li className="relative group">
                  <a href="#sarl" className="text-lg font-medium text-gray-700 block">
                    Carte de
                    transport
                  </a>
                  <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
