// src/components/HeroSection.js
import React from 'react';
import AboutImg from '../assets/woman-working-computer.jpg';
const HeroSection = () => {
  return (
    <section className="bg-gray-100 py-20">
      <div className="container mx-auto flex flex-wrap items-center">
        <div className="w-full md:w-1/2 px-4   mb-3">
          <h1 className="text-5xl font-bold mb-4 text-slate-800">L’expert-comptable en ligne qui vous aide à créer votre entreprise</h1>
          <p className="text-xl mb-6">Créez votre entreprise, pilotez votre comptabilité et prenez de bonnes décisions, sans la charge mentale.</p>
          <button className="bg-blue-500 text-white px-6 py-3 rounded-lg uppercase font-semibold">Créer mon entreprise</button>
        </div>
        <div className="w-full md:w-1/2 px-4">
          {/* <img src="path/to/welcoming-image.jpg" alt="Welcome" className="rounded-lg shadow-lg" /> */}
          <img src={AboutImg} alt="Illustration" className="rounded-lg shadow transform hover:scale-105 transition-transform duration-300" />
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
