// src/App.js
import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Navigation from './Navigation';

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Navbar />
      <Navigation />
      <Footer />
    </div>
  );
}

export default App;
