// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-10">
      <div className="container mx-auto flex flex-wrap">
        <div className="w-full md:w-1/3 px-4 mb-8">
          <h2 className="text-2xl font-bold mb-4">Yanily Expertise Financière</h2>
          <p>Services financiers et comptables pour votre entreprise.</p>
        </div>
        <div className="w-full md:w-1/3 px-4 mb-8">
          <h2 className="text-2xl font-bold mb-4">Services</h2>
          <ul>
            <li>Création d'entreprise</li>
            <li>Gestion d'entreprise</li>
            <li>Assistances</li>
            <li>Autres Démarches</li>
          </ul>
        </div>
        <div className="w-full md:w-1/3 px-4 mb-8">
          <h2 className="text-2xl font-bold mb-4">Contact</h2>
          <p>Email: contact@yanilyexpertisefinanciere.com</p>
          <p>Téléphone: +225 07 0725 6823</p>
          <p>Téléphone: +225 01 0326 9723 </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
