// src/components/CallToAction.js
import React from 'react';
import AboutImg from '../assets/businessman.jpg';
const CallToAction = () => {
  return (
    <section className="bg-blue-500 text-white py-20">
      <div className="container mx-auto flex flex-wrap">
        <div className="w-full md:w-1/2 px-4 mb-8">
          <h2 className="text-3xl font-bold mb-4">Prêt à créer votre entreprise ?</h2>
          <p className="text-xl mb-4">Contactez-nous dès aujourd'hui et lancez-vous dans l'aventure entrepreneuriale avec nous.</p>
          <button className="bg-white text-blue-500 px-6 py-3 rounded-lg">Contactez-nous</button>
        </div>
        <div className="w-full md:w-1/2 px-4">
          {/* <img src="path/to/call-to-action-image.jpg" alt="Call to action" className="rounded-lg shadow-lg" /> */}
          {/* <img src={AboutImg} alt="Illustration" className="rounded-lg shadow-lg  transform hover:scale-105 transition-transform duration-300" style={{width:200, height:200}} /> */}
        </div>
      </div>
    </section>
  );
}

export default CallToAction;
