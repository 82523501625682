// src/components/AboutUs.js
import React from 'react';
import AboutImg from '../assets/ceo-working.jpg';
const AboutUs = () => {
  return (
    <section className="py-20 bg-gray-100">
      <div className="container mx-auto flex flex-wrap">
        <div className="w-full md:w-1/2 px-4 mb-3">
          <h2 className="text-3xl font-bold mb-5 text-slate-800">Construisez et créez votre entreprise grâce au n°<span className='text-4xl font-bold text-slate-700'>1</span> du marché : simple, clair et rapide..</h2>
          {/* <h3 className="text-2xl font-bold mb-4">Un accompagnement au quotidien</h3> */}
          {/* <p className="text-xl mb-4">Construisez et créez votre entreprise grâce au n°1 du marché : simple, clair et rapide..</p> */}
          <div className='border-l-4  border-l-blue-500  p-3 rounded-sm mb-3 shadow-lg bg-slate-50'>
            <h1 className="text-2xl font-semibold mb-2 text-gray-800">Une équipe d’experts</h1>
            <p className='text-[16px]'>Avocats, juristes et experts-comptables vous assurent un accompagnement personnalisé.</p>
          </div>
          <div className='border-l-4 border-l-blue-500  p-3 rounded-sm mb-3  bg-slate-50'>
            <h1 className="text-2xl font-semibold mb-2  text-gray-800">100% en ligne pour plus de réactivité</h1>
            <p className='text-[16px]'>Formulaires, appels, messages ou visio, signature électronique pour un maximum d'efficacité</p>
            </div>
            <div className='border-l-4 border-l-blue-500 p-3  bg-slate-50'>
            <h1 className="text-2xl font-semibold mb-2  text-gray-800 ">Des prix justes et transparents</h1>
            <p className='text-[16px]'>Nos formules s'adaptent à vos besoins et votre budget, pour une prestation sans frais cachés.</p></div>
        </div>
        <div className="w-full md:w-1/2 px-4 mb-8 bg-decorative">
          <img src={AboutImg} alt="Illustration" className="rounded-lg shadow transform hover:scale-105 transition-transform duration-300" />
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
