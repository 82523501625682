import React, { useEffect, useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import { Button } from 'react-scroll';
import illustrationImage from '../assets/illustrationImg2.jpg';
import api from '../api';
import parse from 'html-react-parser';
function Ressources() {
    const { search } = useLocation();
    const [post, SetPost] = useState({
        id: "",
        title: "",
        message: "",
    });

    // const [postByService, SetByService] = useState([]);
    const myLink = () => {
        const urlParams = new URLSearchParams(window.location.search);
        console.log("id url", urlParams.get("idservicerequest"));
        return urlParams.get("idservicerequest");
    };

    const fetchPostByService = () => {
        api.get(`posts/fetchPostByService/` + myLink()).then((res) => {
            SetPost({
                id: res.data.result.posts.id,
                title: res.data.result.posts[0].title,
                message: res.data.result.posts[0].body,
            })
        });

    }
    useEffect(() => {
        fetchPostByService();
    }, [myLink()]);


    return (
        <>
            <div className="min-h-screen bg-gray-100">
                {/* Section 1: Bannière */}
                <section className="bg-blue-300 text-slate-900 py-12">
                    <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between">
                        {/* Colonne de gauche */}
                        <div className="w-full md:w-1/2 lg:w-1/2 mb-8 md:mb-0 p-4">
                            <h1 className="text-3xl font-bold mb-4">
                                {/* Déléguez la création de votre SARL {myLink()} : nous nous chargeons de toutes les formalités */}
                                {post.title}
                            </h1>
                            <p className="mb-4">
                                {/* Bénéficiez d'un accompagnement personnalisé pour créer votre SARL en toute sérénité.
                                    Un expert à vos côtés pour simplifier la création de votre SARL.
                                    Confiez-nous la création de votre SARL : nous nous chargeons de tout. */}
                                {
                                    parse(post.message
                                    )
                                }
                            </p>
                            {/* <p className="mb-4">
                                    Il répond à vos questions et rédige vos statuts. Il vous conseille à chaque étape de la création de votre SARL.
                                </p> */}
                            {/* <Button variant="contained" color="secondary">En savoir plus</Button> */}
                            <button className="bg-blue-500 text-white px-6 py-3 rounded-lg uppercase font-semibold">Créer mon entreprise SARL</button>
                        </div>

                        {/* Colonne de droite avec l'image */}
                        <div className="w-full md:w-1/2 lg:w-1/2 flex justify-center">
                            <img src={illustrationImage} alt="Illustration" className="w-full h-auto max-w-xl rounded" />
                        </div>
                    </div>
                </section>

                {/* Section 2: Conteneur avec une colonne */}
                <section className="py-12 bg-gray-100">
                    <div className="container mx-auto px-4 text-center">
                        <div className="w-full p-4">
                            <h2 className="text-3xl font-semibold mb-4">Votre expert-comptable YanilyExpertiseFinancière est toujours disponible</h2>
                            <p>
                                Démarrez votre création sans stress avec YanilyExpertiseFinancière.
                                Un vrai juriste répond à vos questions et rédige vos statuts.
                                Il prend en charge toutes les démarches administratives et vous conseille tout au long de la création de votre société.
                            </p>
                        </div>
                    </div>
                </section>


            </div>
        </>

    )
}

export default Ressources;