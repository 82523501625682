import React from 'react'
import HomePage from './HomePage'
import { Route, Routes } from 'react-router-dom'
import ServiceRequest from './components/ServiceRequest'
import CoporateManagement from './components/CoporateManagement'
import Accompagnement from './components/Accompagnement'
import AutreDemarche from './components/AutreDemarche'
import AppointmentForm from './components/AppointmentForm'
import Ressources from './components/Ressources'
import LoginPage from './components/LoginPage'

function Navigation() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/creation-entreprise" element={<ServiceRequest />} />
      <Route path="/gestion-entreprise" element={<CoporateManagement />} />
      <Route path="/accompagnement" element={<Accompagnement />} />
      <Route path="/autres-demarches" element={<AutreDemarche />} />
      <Route path="/ressources" element={<Ressources />} />
      <Route path="/espace-client" element={<AppointmentForm />} />
      <Route path="/login/customers" element={<LoginPage />} />
    </Routes>


  )
}

export default Navigation