// src/AppointmentForm.js
import React, { useState, useEffect } from 'react';
import api from '../api';
import toast, { Toaster } from 'react-hot-toast';
const AppointmentForm = () => {
    const notify = () => toast.success('Informations enregistrées avec succès.');
    const [secteurs, setSecteurs] = useState([])
    const fetchSecteurs = () => {
        api.get(`secteurs/`).then((res) => {
            const secteur = res.data.result.secteurs;
            setSecteurs(secteur);
        });
    };

    useEffect(() => {
        fetchSecteurs();
    }, []);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        sector: '',
        message: '',
        acceptedTerms: false,
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        sector: '',
        message: '',
        acceptedTerms: '',
    });

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        // Vérifier si tous les champs sont remplis correctement
        const validateForm = () => {
            const hasError = Object.values(errors).some(error => error !== '');
            const isFormComplete = Object.values(formData).every(value => value !== '' && value !== false);
            setIsFormValid(!hasError && isFormComplete);
        };
        validateForm();
    }, [formData, errors]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });

        // Validation simple lors de la saisie
        let newErrors = { ...errors };
        if (name === 'firstName' && !value) {
            newErrors.firstName = 'Le prénom est requis.';
        } else {
            newErrors.firstName = '';
        }
        if (name === 'lastName' && !value) {
            newErrors.lastName = 'Le nom est requis.';
        } else {
            newErrors.lastName = '';
        }
        if (name === 'email' && (!value || !/\S+@\S+\.\S+/.test(value))) {
            newErrors.email = 'Un email valide est requis.';
        } else {
            newErrors.email = '';
        }
        if (name === 'phone' && (!value || !/^\d{10}$/.test(value))) {
            newErrors.phone = 'Un numéro de téléphone valide est requis.';
        } else {
            newErrors.phone = '';
        }
        if (name === 'sector' && !value) {
            newErrors.sector = 'Le secteur d\'activité est requis.';
        } else {
            newErrors.sector = '';
        }
        if (name === 'message' && !value) {
            newErrors.message = 'Le message est requis.';
        } else {
            newErrors.message = '';
        }
        if (name === 'acceptedTerms' && !checked) {
            newErrors.acceptedTerms = 'Vous devez accepter les CGU.';
        } else {
            newErrors.acceptedTerms = '';
        }
        setErrors(newErrors);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation finale
        let hasError = false;
        let newErrors = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            sector: '',
            message: '',
            acceptedTerms: '',
        };

        if (!formData.firstName) {
            newErrors.firstName = 'Le prénom est requis.';
            hasError = true;
        }
        if (!formData.lastName) {
            newErrors.lastName = 'Le nom est requis.';
            hasError = true;
        }
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Un email valide est requis.';
            hasError = true;
        }
        if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
            newErrors.phone = 'Un numéro de téléphone valide est requis.';
            hasError = true;
        }
        if (!formData.sector) {
            newErrors.sector = 'Le secteur d\'activité est requis.';
            hasError = true;
        }
        if (!formData.message) {
            newErrors.message = 'Le message est requis.';
            hasError = true;
        }
        if (!formData.acceptedTerms) {
            newErrors.acceptedTerms = 'Vous devez accepter les CGU.';
            hasError = true;
        }

        if (hasError) {
            setErrors(newErrors);
            return;
        }

        setErrors({});
        // Traitez les données du formulaire ici
        console.log('Form Data:', formData);
        api.post(`/Customers/add`, formData).then((res) => {
            if (res.data.status === "OK") {
                notify();
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    sector: '',
                    message: '',
                    acceptedTerms: false,
                })

            } else {
                // this.ErrorSwal();
            }
        });
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 ">
            <div className="w-full max-w-4xl bg-white p-5 rounded-lg shadow-sm mt-5 mb-5">
                <h1 className="text-2xl font-bold mb-6 text-center">Faisons connaissance  😊</h1>
                <h1 className="text-xl font-light mb-6 text-center">Nous avons besoin de quelques informations pour préparer au mieux votre rendez-vous!</h1>
                <Toaster />
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="mb-4">
                            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">Prénoms *</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                            {errors.firstName && <p className="text-red-500 text-sm">{errors.firstName}</p>}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Nom *</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                            {errors.lastName && <p className="text-red-500 text-sm">{errors.lastName}</p>}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email *</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Numéro de téléphone * (whatsApp)</label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                            {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="sector" className="block text-sm font-medium text-gray-700">Secteur d'activité *</label>
                            <select
                                id="sector"
                                name="sector"
                                value={formData.sector}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            >
                                <option selected disabled value="">
                                    Selectionnez...
                                </option>
                                {secteurs.map((activity, id) => (
                                    <option value={activity.id} key={id}>
                                        {activity.title}
                                    </option>
                                ))}
                            </select>
                            {errors.sector && <p className="text-red-500 text-sm">{errors.sector}</p>}
                        </div>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            rows="4"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
                    </div>
                    <div className="mb-4 flex items-center">
                        <input
                            type="checkbox"
                            id="acceptedTerms"
                            name="acceptedTerms"
                            checked={formData.acceptedTerms}
                            onChange={handleChange}
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                        <label htmlFor="acceptedTerms" className="ml-2 text-sm font-medium text-gray-700">
                            J'accepte les <a href="/terms" className="text-blue-500 hover:text-blue-600">Conditions Générales d'Utilisation</a>
                        </label>
                        {errors.acceptedTerms && <p className="text-red-500 text-sm">{errors.acceptedTerms}</p>}
                    </div>
                    <button
                        type="submit"
                        disabled={!isFormValid}
                        className={`w-full py-2 px-4 ${isFormValid ? 'bg-blue-500' : 'bg-gray-300'} text-white font-bold rounded-lg shadow-sm hover:${isFormValid ? 'bg-blue-600' : 'bg-gray-400'} focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    >
                        Envoyer
                    </button>

                </form>
            </div>
        </div>
    );
};

export default AppointmentForm;
