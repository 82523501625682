// File: src/components/SarlFaqAccordion.js
import React, { useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import api from '../api';

const SarlFaqAccordion = () => {

  const myLink = () => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams.get("idservicerequest"))
    return urlParams.get("idservicerequest");
  };



  const [faq, setFaq] = useState([])

  const fecthFaqByServices = () => {
    api.get(`ServicesFaq/getFaqByServices/` + myLink()).then((res) => {
      setFaq(res.data.result.servicesFaq)

    });

  }

  useEffect(() => {
    fecthFaqByServices();
  }, [myLink()]);


  const faqs = [
    {
      question: "Qu'est-ce qu'une SARL ?",
      answer: "La SARL (Société à Responsabilité Limitée) est une forme de société où la responsabilité des associés est limitée au montant de leurs apports."
    },
    {
      question: "Comment créer une SARL ?",
      answer: "Pour créer une SARL, il faut rédiger les statuts, déposer le capital social, publier une annonce légale et immatriculer la société au registre du commerce."
    },
    {
      question: "Quel est le capital minimum pour créer une SARL ?",
      answer: "Il n'y a pas de capital minimum requis pour créer une SARL. Vous pouvez créer une SARL avec un capital de 1€."
    },
    {
      question: "Qui peut être gérant d'une SARL ?",
      answer: "Le gérant d'une SARL peut être un ou plusieurs associés ou une personne extérieure. Il est désigné par les statuts ou par décision des associés."
    }
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Fermer si déjà ouvert
    } else {
      setActiveIndex(index); // Ouvrir la section cliquée
    }
  };

  return (
    <div className="w-full  mx-auto mt-10 space-y-4 py-5">
      {faq.map((faqs, index) => (
        <div key={index} className="border rounded-lg shadow-sm">
          {/* Question Button */}
          <button
            className="flex justify-between items-center w-full p-4 font-medium text-lg text-gray-800 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded-t-lg"
            onClick={() => toggleAccordion(index)}
          >
            <span>{faqs.title}</span>
            {activeIndex === index ? (
              <ChevronUpIcon className="w-5 h-5 text-indigo-500 transition-transform duration-300" />
            ) : (
              <ChevronDownIcon className="w-5 h-5 text-gray-500 transition-transform duration-300" />
            )}
          </button>
          {/* Answer Section */}
          <div
            className={`overflow-hidden transition-all duration-300 ${activeIndex === index ? 'max-h-96' : 'max-h-0'
              }`}
          >
            <p className="p-4 text-gray-600 bg-white">
              {faqs.body}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SarlFaqAccordion;
