// src/LoginPage.js
import React, { useState } from 'react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'; // Import des icônes
import api from '../api';

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false); // Gère la visibilité du mot de passe
  const [error, setError] = useState('');
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("username", form.email);
    data.append("password", form.password);
   // setIsloading(true);
    api.post(`Users/login`, data).then((res) => {
      if (res.data.result.feedback === "success") {
        //sessionStorage.setItem("accessToken", res.data.result.token);
        sessionStorage.setItem("userId", res.data.result.payload.id);
        sessionStorage.setItem("email", res.data.result.payload.email);
        sessionStorage.setItem("userName", res.data.result.payload.username);
        sessionStorage.setItem("SellerId", res.data.result.payload.SellerId);
        //setRedirect(true);
      } else if (res.data.result.feedback === "failed") {
       // setIsloading(false);
        //ErrorSwal();
      }
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-xl bg-white p-5 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-6 text-center">Connexion</h1>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              value={form.email}
              onChange={(event) =>
                handleChange("email", event.target.value)
              }
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div className="mb-6 relative">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Mot de passe</label>
            <input
              type={showPassword ? 'text' : 'password'} // Type dynamique de l'input
              id="password"
              value={form.password}
              onChange={(event) =>
                handleChange("password", event.target.value)
              }
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
            {/* Bouton avec icône pour voir/cacher le mot de passe */}
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)} // Toggle entre afficher/cacher le mot de passe
              className="absolute inset-y-0 right-0 px-3 py-2 mt-5 text-gray-600 hover:text-gray-800 transition-all duration-300 ease-in-out focus:outline-none"
            >
              {showPassword ? (
                <EyeOffIcon className="w-5 h-5" /> // Icône œil barré pour cacher le mot de passe
              ) : (
                <EyeIcon className="w-5 h-5" /> // Icône œil pour voir le mot de passe
              )}
            </button>
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-500 text-white font-bold rounded-lg shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Se connecter
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
