import React, { useEffect, useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import { Button } from 'react-scroll';
import illustrationImage from '../assets/coporate.jpg';
import PatnersImage from '../assets/patners.jpg';
import WhyChooseUs from './WhyChooseUs';
import HowWork from './HowWork';
import { Collapse } from 'react-collapse';
import api from '../api';
import parse from 'html-react-parser';

function CoporateManagement() {
    const { search } = useLocation();
    
    const [post, SetPost] = useState({
        id: "",
        title: "",
        message: "",
        category:"",
    });

    const myLink = () => {
        const urlParams = new URLSearchParams(window.location.search);
        console.log(urlParams.get("idservicerequest"))
        return urlParams.get("idservicerequest");
    };

    const fetchPostByService = () => {
        api.get(`posts/fetchPostByService/` + myLink()).then((res) => {
            SetPost({
                id: res.data.result.posts.id,
                title: res.data.result.posts[0].title,
                message: res.data.result.posts[0].body,
                category:res.data.result.posts[0].service.title,
            })
        });

    }

    useEffect(() => {
        if (post) {
            fetchPostByService();
        }
    }, [myLink()]);

    const AccordionItem = ({ title, children, isOpen, onClick }) => (
        <div className="mb-4">
            <button
                onClick={onClick}
                className="w-full text-left bg-gray-100 p-4 rounded-lg shadow-md focus:outline-none"
            >
                <h3 className="text-lg font-semibold">{title}</h3>
            </button>
            <Collapse isOpened={isOpen}>
                <div className="p-4 bg-white rounded-lg shadow-sm mt-2">
                    {children}
                </div>
            </Collapse>
        </div>
    );
    const [openIndex, setOpenIndex] = useState(null);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <>
            <div className="min-h-screen bg-gray-100">
                {/* Section 1: Bannière */}
                <section className="bg-sky-300 text-slate-900 py-12">
                    <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between">
                        {/* Colonne de gauche */}
                        <div className="w-full md:w-1/2 lg:w-1/2 mb-8 md:mb-0 p-4">
                            <h1 className="text-3xl font-bold mb-4">
                                {post.title}
                            </h1>
                            <p className="mb-4">
                                {
                                    parse(post.message
                                    )
                                }  </p>

                            {/* <Button variant="contained" color="secondary">En savoir plus</Button> */}
                            {/* <button className="bg-blue-500 text-white px-6 py-3 rounded-lg uppercase font-semibold">Créer mon entreprise SARL</button> */}
                            <Link to={'/espace-client'} className="bg-blue-500 text-white px-6 py-3 rounded-lg uppercase font-semibold">
                               {post.category}
                            </Link>
                        </div>

                        {/* Colonne de droite avec l'image */}
                        <div className="w-full md:w-1/2 lg:w-1/2 flex justify-center">
                            <img src={illustrationImage} alt="Illustration" className="w-full h-auto max-w-xl rounded-xl" />
                        </div>
                    </div>
                </section>

                {/* Section 2: Conteneur avec une colonne */}
                <HowWork />

                {/* Nouvelle Section: Votre projet est entre de bonnes mains */}
                <section className="py-16  bg-sky-100 ">

                    <div className="container mx-auto p-4  flex flex-col md:flex-row items-center justify-between  bg-sky-300 rounded-xl">
                        {/* Colonne avec l'accordéon */}
                        {/* <h1 className="text-3xl font-bold mb-4">
                                Changez de dirigeant en ligne en toute simplicité ! {myLink()} : nous nous chargeons de toutes les formalités
                            </h1> */}
                        {/* <h2 className="text-2xl font-semibold mb-4">Votre projet est entre de bonnes mains</h2> */}
                        <div className="w-full md:w-1/2 lg:w-1/2 mb-8 md:mb-0">
                            <h1 className="text-3xl font-bold mb-4">
                                Votre projet est entre de bonnes mains
                            </h1>
                            <AccordionItem
                                title="Des prix justes et transparents"
                                isOpen={openIndex === 0}
                                onClick={() => handleToggle(0)}
                            >
                                Nos différentes formules d’accompagnement s’adaptent à vos besoins et votre budget, pour une prestation sans frais cachés.
                            </AccordionItem>
                            <AccordionItem
                                title="Une démarche en ligne, 100 % sécurisée"
                                isOpen={openIndex === 1}
                                onClick={() => handleToggle(1)}
                            >
                                Formulaires en ligne, échanges avec nos juristes à distance et signature électronique : gagnez en efficacité à chaque étape de votre projet.
                            </AccordionItem>
                            <AccordionItem
                                title="Une équipe attentive et disponible"
                                isOpen={openIndex === 2}
                                onClick={() => handleToggle(2)}
                            >
                                Par téléphone, message ou en visio, nos juristes spécialisés vous répondent à tout moment de votre projet, de manière illimitée.
                            </AccordionItem>
                            <AccordionItem
                                title="Un réseau de partenaires de confiance"
                                isOpen={openIndex === 3}
                                onClick={() => handleToggle(3)}
                            >
                                Pour un accompagnement complet, nous avons sélectionné des partenaires fiables : avocats, comptables, banque en ligne etc.
                            </AccordionItem>
                        </div>

                        {/* Colonne avec l'image */}
                        <div className="w-full md:w-1/2 lg:w-1/2 flex justify-center">
                            <img src={PatnersImage} alt="Illustration" className="w-full h-auto max-w-md rounded-xl " />
                        </div>
                    </div>
                </section>
                {/* Section 3: FAQ sur la création d'entreprise */}
                <section className="bg-gray-100 py-26">
                    <div className="container mx-auto px-4">
                        <div className="w-full p-4">
                            <h2 className="text-2xl font-semibold mb-4">Création de votre SARL : Questions indispensables pour se lancer</h2>
                            <div className="space-y-6">
                                <div className="bg-white p-6 rounded-lg shadow-md">
                                    <h3 className="text-xl font-semibold">Question 1</h3>
                                    <p className="mt-2">
                                        Réponse à la première question fréquemment posée concernant la création d'entreprise.
                                    </p>
                                </div>
                                <div className="bg-white p-6 rounded-lg shadow-md">
                                    <h3 className="text-xl font-semibold">Question 2</h3>
                                    <p className="mt-2">
                                        Réponse à la deuxième question fréquemment posée concernant la création d'entreprise.
                                    </p>
                                </div>
                                <div className="bg-white p-6 rounded-lg shadow-md">
                                    <h3 className="text-xl font-semibold">Question 3</h3>
                                    <p className="mt-2">
                                        Réponse à la deuxième question fréquemment posée concernant la création d'entreprise.
                                    </p>
                                </div>
                                <div className="bg-white p-6 rounded-lg shadow-md">
                                    <h3 className="text-xl font-semibold">Question 4</h3>
                                    <p className="mt-2">
                                        Réponse à la deuxième question fréquemment posée concernant la création d'entreprise.
                                    </p>
                                </div>
                                <div className="bg-white p-6 rounded-lg shadow-md">
                                    <h3 className="text-xl font-semibold">Question 5</h3>
                                    <p className="mt-2">
                                        Réponse à la deuxième question fréquemment posée concernant la création d'entreprise.
                                    </p>
                                </div>
                                <div className="bg-white p-6 rounded-lg shadow-md">
                                    <h3 className="text-xl font-semibold">Question 6</h3>
                                    <p className="mt-2">
                                        Réponse à la deuxième question fréquemment posée concernant la création d'entreprise.
                                    </p>
                                </div>
                                {/* Ajoutez plus de questions et réponses ici */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>

    )
}

export default CoporateManagement