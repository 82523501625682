import React, { useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { HiMenu, HiX, HiChevronDown } from 'react-icons/hi';
import api from '../api';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [service1, SetService1] = useState([]);
  const [service2, SetService2] = useState([]);
  const [service3, SetService3] = useState([]);
  const [service4, SetService4] = useState([]);
  const [service5, SetService5] = useState([]);
  const fetchService = () => {
    api.get(`services/service1`).then((res) => {
      const services1 = res.data.result.services;
      SetService1(services1);
    });
    api.get(`services/service2`).then((res) => {
      const services2 = res.data.result.services;
      SetService2(services2);
    });
    api.get(`services/service3`).then((res) => {
      const services3 = res.data.result.services;
      SetService3(services3);
    });
    api.get(`services/service4`).then((res) => {
      const services4 = res.data.result.services;
      SetService4(services4);
    });
    api.get(`services/service5`).then((res) => {
      const services5 = res.data.result.services;
      SetService5(services5);
    });
  };
  useEffect(() => {
    fetchService();
  }, []);
  return (
    <header className="bg-white shadow">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo */}
        <div className="text-2xl font-bold">YExpFinancière</div>
        {/* Hamburger Icon for Mobile */}
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="text-gray-800 hover:text-gray-600 focus:outline-none focus:text-gray-600"
          >
            {isOpen ? (
              <HiX className="h-6 w-6" />
            ) : (
              <HiMenu className="h-6 w-6" />
            )}
          </button>
        </div>
        {/* Menu Links */}
        <nav
          className={`${isOpen ? 'flex' : 'hidden'
            } flex-col md:flex-row md:flex md:space-x-4 absolute md:static top-16 left-0 right-0 bg-white md:bg-transparent p-4 md:p-0 md:items-center md:justify-between z-50`}
        >
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="flex items-center text-gray-700 hover:text-blue-500">
              Création entreprise <HiChevronDown className="w-5 h-5 ml-1" />
            </Menu.Button>
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 mt-2 w-96 origin-top-left bg-white border border-gray-200 rounded-md shadow-lg focus:outline-none grid grid-cols-2 gap-4 p-4">
                {
                  service1.map((item, index) => (
                    <Menu.Item key={index}>
                      <Link to={"/creation-entreprise?idservicerequest=" + item.id}
                        //   to={"/detail-product?IdProd=" + prod.id}
                        className="block text-gray-700 hover:bg-gray-100 p-2 rounded">
                        {item.title}
                      </Link>
                    </Menu.Item>
                  ))
                }
              </Menu.Items>
            </Transition>
          </Menu>
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="flex items-center text-gray-700 hover:text-blue-500">
              Gestion entreprise <HiChevronDown className="w-5 h-5 ml-1" />
            </Menu.Button>
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 mt-2 w-96 origin-top-left bg-white border border-gray-200 rounded-md shadow-lg focus:outline-none grid grid-cols-2 gap-4 p-4">
                {
                  service2.map((item, index) => (
                    <Menu.Item key={index}>
                      <Link to={"/gestion-entreprise?idservicerequest=" + item.id}
                        //   to={"/detail-product?IdProd=" + prod.id}
                        className="block text-gray-700 hover:bg-gray-100 p-2 rounded">
                        {item.title}
                      </Link>
                    </Menu.Item>
                  ))
                }
              </Menu.Items>
            </Transition>
          </Menu>
          {/* <a href="#gestion" className="text-gray-700 hover:text-blue-500 block md:inline-block">
           
          </a> */}
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="flex items-center text-gray-700 hover:text-blue-500">
              Accompagnement <HiChevronDown className="w-5 h-5 ml-1" />
            </Menu.Button>
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 mt-2 w-96 origin-top-left bg-white border border-gray-200 rounded-md shadow-lg focus:outline-none grid grid-cols-2 gap-4 p-4">
                {
                  service3.map((item, index) => (
                    <Menu.Item key={index}>
                      <Link to={"/accompagnement?idservicerequest=" + item.id}
                        //   to={"/detail-product?IdProd=" + prod.id}
                        className="block text-gray-700 hover:bg-gray-100 p-2 rounded">
                        {item.title}
                      </Link>
                    </Menu.Item>
                  ))
                }
              </Menu.Items>
            </Transition>
          </Menu>
          {/* <a href="#autres-demarches" className="text-gray-700 hover:text-blue-500 block md:inline-block">
            Autres Démarches
          </a> */}
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="flex items-center text-gray-700 hover:text-blue-500">
              Autres Démarches <HiChevronDown className="w-5 h-5 ml-1" />
            </Menu.Button>
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 mt-2 w-96 origin-top-left bg-white border border-gray-200 rounded-md shadow-lg focus:outline-none grid grid-cols-2 gap-4 p-4">
                {
                  service4.map((item, index) => (
                    <Menu.Item key={index}>
                      <Link to={"/autres-demarches?idservicerequest=" + item.id}
                        //   to={"/detail-product?IdProd=" + prod.id}
                        className="block text-gray-700 hover:bg-gray-100 p-2 rounded">
                        {item.title}
                      </Link>
                    </Menu.Item>
                  ))
                }
              </Menu.Items>
            </Transition>
          </Menu>
          {/* <a href="#ressources" className="text-gray-700 hover:text-blue-500 block md:inline-block">
            Ressources
          </a> */}

          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="flex items-center text-gray-700 hover:text-blue-500">
              Ressources <HiChevronDown className="w-5 h-5 ml-1" />
            </Menu.Button>
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 mt-2 w-96 origin-top-left bg-white border border-gray-200 rounded-md shadow-lg focus:outline-none grid grid-cols-2 gap-4 p-4">
                {
                  service5.map((item, index) => (
                    <Menu.Item key={index}>
                      <Link to={"/gestion-entreprise?idservicerequest=" + item.id}
                        //   to={"/detail-product?IdProd=" + prod.id}
                        className="block text-gray-700 hover:bg-gray-100 p-2 rounded">
                        {item.title}
                      </Link>
                    </Menu.Item>
                  ))
                }
              </Menu.Items>
            </Transition>
          </Menu>
          {/* <a href=" tel:2250707256823" className=" text-white bg-sky-700 hover:text-blue-500 border-2 border-sky-600 px-4 py-2 rounded-md block  md:inline-block">

            <span> 📞 07 0725 6823</span>
          </a>*/}
         
          <Link to={'/login/customers'} className="text-white bg-sky-500 hover:text-blue-500 border-2 border-sky-400 px-4 py-2 rounded-md block md:inline-block">
            👤 Espace Client
          </Link> 
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
