// src/components/WhyChooseUs.js
import React from 'react';
import { Link } from 'react-router-dom';

const HowWork = () => {
  return (
    <section className="py-24">
      <div className="container mx-auto ">
        <h2 className="text-3xl font-bold text-center">Transformation de SARL en SAS : Comment ça marche ?
          <p className='mb-10 text-center text-xl font-light '>🚀 Vous développez votre activité, nous gérons votre juridique !</p>
        </h2>

        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/3 px-4 mb-8">
            <div className="p-6 bg-white shadow-lg rounded-lg">
              <div className='flex items-center gap-x-4 mb-3'>
                {/* <svg xmlns="http://www.w3.org/2000/svg" className='text-blue-700 size-12' fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m0-3-3-3m0 0-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75" />
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" className='text-blue-700 size-12' fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                </svg>
                <h3 className="text-2xl font-bold mb-4"> Questionnaire en ligne</h3>
              </div>
              <p>Un simple questionnaire sur votre entreprise à remplir en ligne <br/>Précisez votre projet et vos attentes.  <Link to={'/espace-client'}className='underline text-blue-700'>Commencer</Link> </p>
            </div>
          </div>
          <div className="w-full md:w-1/3 px-4 mb-8">
            <div className="p-6 bg-white shadow-lg rounded-lg">
              <div className='flex items-center gap-x-4 mb-3'>
                <svg xmlns="http://www.w3.org/2000/svg" className='text-green-700 size-12' fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
                </svg>
                <h3 className="text-2xl font-bold mb-4">Dossier vérifié en 24h</h3>
              </div>
              <p>Nos juristes répondent à vos questions et vérifient votre dossier</p>
            </div>
          </div>
          <div className="w-full md:w-1/3 px-4 mb-8">
            <div className="p-6 bg-white shadow-lg rounded-lg">
              <div className='flex items-center gap-x-4 mb-3'>

                <svg xmlns="http://www.w3.org/2000/svg" className="size-12 text-orange-700" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>

                <h3 className="text-2xl font-bold mb-4"> Profitez de formules adaptées</h3>
              </div>
              <p>Nos formules sont adaptées à vos besoins. En 48h, vos statuts et dossier sont envoyés au greffe</p>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default HowWork;
