import React from 'react'
import HeroSection from './components/HeroSection';
import WhyChooseUs from './components/WhyChooseUs';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import CallToAction from './components/CallToAction';
function HomePage() {
    return (
        <>
            <HeroSection />
            <WhyChooseUs />
            <AboutUs />
            <Services />
            <CallToAction />
        </>

    )
}

export default HomePage