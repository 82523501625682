// src/components/WhyChooseUs.js
import React from 'react';

const WhyChooseUs = () => {
  return (
    <section className="py-20">
      <div className="container mx-auto ">
        <h2 className="text-3xl font-bold mb-10 text-center">Pourquoi choisir notre entreprise d'expertise</h2>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/3 px-4 mb-8">
            <div className="p-6 bg-white shadow-lg rounded-lg">
              <div className='flex items-center gap-x-4 mb-3'>
                <svg xmlns="http://www.w3.org/2000/svg" className='text-blue-700 size-12' fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m0-3-3-3m0 0-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75" />
                </svg>

                <h3 className="text-2xl font-bold mb-4"> Soyez bien accompagné par nous</h3>
              </div>
              <p>Votre conseiller dédié est disponible par téléphone, par mail ou visio pour vous accompagner au quotidien dans vos démarches administratives.</p>
            </div>
          </div>
          <div className="w-full md:w-1/3 px-4 mb-8">
            <div className="p-6 bg-white shadow-lg rounded-lg">
              <div className='flex items-center gap-x-4 mb-3'>
                <svg xmlns="http://www.w3.org/2000/svg" class='text-green-700 size-12' fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0" />
                </svg>

                <h3 className="text-2xl font-bold mb-4">Gagnez du temps et de l'argent</h3>
              </div>
              <p>Déléguez votre comptabilité et concentrez vous sur votre cœur de métier. Vous n'avez qu'à centraliser vos documents sur l'app <b>Yanily-compta</b> et on se charge de tout.</p>
            </div>
          </div>
          <div className="w-full md:w-1/3 px-4 mb-8">
            <div className="p-6 bg-white shadow-lg rounded-lg">
              <div className='flex items-center gap-x-4 mb-3'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-12 text-orange-600">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                </svg>

                <h3 className="text-2xl font-bold mb-4"> Développez votre entreprise</h3>
              </div>
              <p>Quel statut choisir ? Comment optimiser sa rémunération ? Que faire de son bénéfice ? Nous répondons à ces questions pour que vous preniez les bonnes décisions.</p>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;
